import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { HelmetDatoCms } from "gatsby-source-datocms"
import ImageFadeIn from "react-image-fade-in"
import { Layout } from "../components/layout"
import { DividerBlock } from "../components/dividerBlock"

const People = ({ data }) => {
  const { people } = data
  return (
    <Layout
      bgcolor={people.backgroundColor}
      wrapperClass="people"
      accentColor={people.accentColor}
    >
      <HelmetDatoCms seo={people.seoMetaTags} />
      <div className="people-intro container row">
        <div className="col-sm-7 offset-sm-2 col-xs-6 col">
          <h1 className="gothic--large large-title">{people.title}</h1>
        </div>
      </div>
      {people.staff.length > 0 && (
        <section
          aria-labelledby="people-s"
          className="people-staff container row"
        >
          <div className="col-sm-2 col-xs-6 col">
            <h2 id="people-s" className="sidebar-text">
              Staff
            </h2>
          </div>
          <ul className="col-sm-11 col-xs-6 col spacing-0">
            {people.staff.map(({ id, image, name, bio }) => (
              <li className="person-row" key={id}>
                <div className="person-image-wrapper col">
                  <ImageFadeIn src={image.url} alt={image.alt || name} />
                </div>
                <div className="person-text-wrapper col">
                  <h3
                    className="sans--regular"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: name,
                    }}
                  />
                  <div
                    className="person-bio content"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: bio,
                    }}
                  />
                </div>
              </li>
            ))}
          </ul>
          <div className="col-sm-5 offset-sm-3 col-xs-4 offset-xs-1 col">
            <DividerBlock accentColor={people.accentColor} />
          </div>
        </section>
      )}
      {people.board.length > 0 && (
        <section
          aria-labelledby="people-b"
          className="people-board container row"
        >
          <div className="col-sm-2 col-xs-6 col">
            <h2 id="people-b" className="sidebar-text">
              Board
            </h2>
          </div>
          <ul className="col-sm-11 col-xs-6 col spacing-0">
            {people.board.map(({ id, image, name, bio }) => (
              <li className="person-row" key={id}>
                <div className="person-image-wrapper col">
                  <ImageFadeIn src={image.url} alt={image.alt || name} />
                </div>
                <div className="person-text-wrapper col">
                  <h3
                    className="sans--regular"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: name,
                    }}
                  />
                  <div
                    className="person-bio content"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: bio,
                    }}
                  />
                </div>
              </li>
            ))}
          </ul>
        </section>
      )}
    </Layout>
  )
}

People.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()).isRequired,
}

export default People

export const query = graphql`
  query PeopleQuery {
    people: datoCmsPerson {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      accentColor {
        hex
      }
      backgroundColor
      title
      staff {
        id
        image {
          alt
          url(
            imgixParams: { w: "600", h: "600", fit: "crop", crop: "focalpoint" }
          )
        }
        name
        bio
      }
      board {
        id
        image {
          alt
          url(
            imgixParams: { w: "600", h: "600", fit: "crop", crop: "focalpoint" }
          )
        }
        name
        bio
      }
    }
  }
`
